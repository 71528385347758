export default {
  signIn: '/user/authorisation',
  getDisciplines: '/discipline/get',
  addDiscipline: '/discipline/add',
  deleteDisciplines: '/discipline/delete',
  updateDiscipline: '/discipline/update',
  uploadCSV: '/discipline/upload',
  getGroups: '/group/get',
  addGroup: '/group/add',
  updateGroup: '/group/update',
  deleteGroups: '/group/delete',
  getStudents: '/student/get',
  addStudent: '/student/add',
  updateStudent: '/student/update',
  deleteStudents: '/student/delete',
  searchStudent: '/student/search',
  searchStudents: '/student/search/students',
  addListDates: '/student/add/dates',
  getListDates: '/student/get/dates',
  getSearchGroup: '/student/get/search/group',
  getSearchGroups: '/student/get/search/groups',
  getListDatesSearch: '/student/get/dates/search',
};
